// Authentication
export const LOGOUT = 'LOGOUT';
export const LOGIN = 'LOGIN';
export const LOGIN_INFLIGHT = 'LOGIN_INFLIGHT';
export const LOGIN_ERROR = 'LOGIN_ERROR';
// Collections
export const ADD_INSTANCE_META = 'ADD_INSTANCE_META';
export const COLLECTION = 'COLLECTION';
export const COLLECTION_INFLIGHT = 'COLLECTION_INFLIGHT';
export const COLLECTION_ERROR = 'COLLECTION_ERROR';
export const COLLECTION_APPLYWORKFLOW = 'COLLECTION_APPLYWORKFLOW';
export const COLLECTION_APPLYWORKFLOW_INFLIGHT = 'COLLECTION_APPLYWORKFLOW_INFLIGHT';
export const COLLECTION_APPLYWORKFLOW_ERROR = 'COLLECTION_APPLYWORKFLOW_ERROR';
export const COLLECTIONS = 'COLLECTIONS';
export const COLLECTIONS_INFLIGHT = 'COLLECTIONS_INFLIGHT';
export const COLLECTIONS_ERROR = 'COLLECTIONS_ERROR';
export const NEW_COLLECTION = 'NEW_COLLECTION';
export const NEW_COLLECTION_INFLIGHT = 'NEW_COLLECTION_INFLIGHT';
export const NEW_COLLECTION_ERROR = 'NEW_COLLECTION_ERROR';
export const UPDATE_COLLECTION = 'UPDATE_COLLECTION';
export const UPDATE_COLLECTION_INFLIGHT = 'UPDATE_COLLECTION_INFLIGHT';
export const UPDATE_COLLECTION_ERROR = 'UPDATE_COLLECTION_ERROR';
export const UPDATE_COLLECTION_CLEAR = 'UPDATE_COLLECTION_CLEAR';
export const SEARCH_COLLECTIONS = 'SEARCH_COLLECTIONS';
export const CLEAR_COLLECTIONS_SEARCH = 'CLEAR_COLLECTIONS_SEARCH';
export const FILTER_COLLECTIONS = 'FILTER_COLLECTIONS';
export const CLEAR_COLLECTIONS_FILTER = 'CLEAR_COLLECTIONS_FILTER';
export const COLLECTION_DELETE = 'COLLECTION_DELETE';
export const COLLECTION_DELETE_INFLIGHT = 'COLLECTION_DELETE_INFLIGHT';
export const COLLECTION_DELETE_ERROR = 'COLLECTION_DELETE_ERROR';
// Granules
export const GRANULE = 'GRANULE';
export const GRANULE_INFLIGHT = 'GRANULE_INFLIGHT';
export const GRANULE_ERROR = 'GRANULE_ERROR';
export const GRANULES = 'GRANULES';
export const GRANULES_INFLIGHT = 'GRANULES_INFLIGHT';
export const GRANULES_ERROR = 'GRANULES_ERROR';
export const GRANULE_APPLYWORKFLOW = 'GRANULE_APPLYWORKFLOW';
export const GRANULE_APPLYWORKFLOW_INFLIGHT = 'GRANULE_APPLYWORKFLOW_INFLIGHT';
export const GRANULE_APPLYWORKFLOW_ERROR = 'GRANULE_APPLYWORKFLOW_ERROR';
export const GRANULE_APPLYWORKFLOW_CLEAR_ERROR = 'GRANULE_APPLYWORKFLOW_CLEAR_ERROR';
export const GRANULE_REPROCESS = 'GRANULE_REPROCESS';
export const GRANULE_REPROCESS_INFLIGHT = 'GRANULE_REPROCESS_INFLIGHT';
export const GRANULE_REPROCESS_ERROR = 'GRANULE_REPROCESS_ERROR';
export const GRANULE_REINGEST = 'GRANULE_REINGEST';
export const GRANULE_REINGEST_INFLIGHT = 'GRANULE_REINGEST_INFLIGHT';
export const GRANULE_REINGEST_ERROR = 'GRANULE_REINGEST_ERROR';
export const GRANULE_REINGEST_CLEAR_ERROR = 'GRANULE_REINGEST_CLEAR_ERROR';
export const GRANULE_REMOVE = 'GRANULE_REMOVE';
export const GRANULE_REMOVE_INFLIGHT = 'GRANULE_REMOVE_INFLIGHT';
export const GRANULE_REMOVE_ERROR = 'GRANULE_REMOVE_ERROR';
export const GRANULE_REMOVE_CLEAR_ERROR = 'GRANULE_REMOVE_CLEAR_ERROR';
export const GRANULE_RECOVER = 'GRANULE_RECOVER';
export const GRANULE_RECOVER_INFLIGHT = 'GRANULE_RECOVER_INFLIGHT';
export const GRANULE_RECOVER_ERROR = 'GRANULE_RECOVER_ERROR';
export const BULK_GRANULE_DELETE = 'BULK_GRANULE_DELETE';
export const BULK_GRANULE_DELETE_INFLIGHT = 'BULK_GRANULE_DELETE_INFLIGHT';
export const BULK_GRANULE_DELETE_ERROR = 'BULK_GRANULE_DELETE_ERROR';
export const BULK_GRANULE_DELETE_CLEAR_ERROR = 'BULK_GRANULE_DELETE_CLEAR_ERROR';
export const BULK_GRANULE = 'BULK_GRANULE';
export const BULK_GRANULE_INFLIGHT = 'BULK_GRANULE_INFLIGHT';
export const BULK_GRANULE_ERROR = 'BULK_GRANULE_ERROR';
export const BULK_GRANULE_CLEAR_ERROR = 'BULK_GRANULE_CLEAR_ERROR';
export const BULK_GRANULE_REINGEST = 'BULK_GRANULE_REINGEST';
export const BULK_GRANULE_REINGEST_INFLIGHT = 'BULK_GRANULE_REINGEST_INFLIGHT';
export const BULK_GRANULE_REINGEST_ERROR = 'BULK_GRANULE_REINGEST_ERROR';
export const BULK_GRANULE_REINGEST_CLEAR_ERROR = 'BULK_GRANULE_REINGEST_CLEAR_ERROR';
export const GRANULE_DELETE = 'GRANULE_DELETE';
export const GRANULE_DELETE_INFLIGHT = 'GRANULE_DELETE_INFLIGHT';
export const GRANULE_DELETE_ERROR = 'GRANULE_DELETE_ERROR';
export const GRANULE_DELETE_CLEAR_ERROR = 'GRANULE_DELETE_CLEAR_ERROR';
export const SEARCH_GRANULES = 'SEARCH_GRANULES';
export const CLEAR_GRANULES_SEARCH = 'CLEAR_GRANULES_SEARCH';
export const FILTER_GRANULES = 'FILTER_GRANULES';
export const CLEAR_GRANULES_FILTER = 'CLEAR_GRANULES_FILTER';
export const TOGGLE_GRANULES_TABLE_COLUMNS = 'TOGGLE_GRANULES_TABLE_COLUMNS';
export const OPTIONS_COLLECTIONNAME = 'OPTIONS_COLLECTIONNAME';
export const OPTIONS_COLLECTIONNAME_INFLIGHT = 'OPTIONS_COLLECTIONNAME_INFLIGHT';
export const OPTIONS_COLLECTIONNAME_ERROR = 'OPTIONS_COLLECTIONNAME_ERROR';
// Stats
export const STATS = 'STATS';
export const STATS_INFLIGHT = 'STATS_INFLIGHT';
export const STATS_ERROR = 'STATS_ERROR';
// Count
export const COUNT = 'COUNT';
export const COUNT_SIDEBAR = 'COUNT_SIDEBAR';
export const COUNT_INFLIGHT = 'COUNT_INFLIGHT';
export const COUNT_ERROR = 'COUNT_ERROR';
// PDR
export const PDR = 'PDR';
export const PDR_INFLIGHT = 'PDR_INFLIGHT';
export const PDR_ERROR = 'PDR_ERROR';
export const PDRS = 'PDRS';
export const PDRS_INFLIGHT = 'PDRS_INFLIGHT';
export const PDRS_ERROR = 'PDRS_ERROR';
export const PDR_DELETE = 'PDR_DELETE';
export const PDR_DELETE_INFLIGHT = 'PDR_DELETE_INFLIGHT';
export const PDR_DELETE_ERROR = 'PDR_DELETE_ERROR';
export const SEARCH_PDRS = 'SEARCH_PDRS';
export const CLEAR_PDRS_SEARCH = 'CLEAR_PDRS_SEARCH';
export const FILTER_PDRS = 'FILTER_PDRS';
export const CLEAR_PDRS_FILTER = 'CLEAR_PDRS_FILTER';
// Providers
export const PROVIDER = 'PROVIDER';
export const PROVIDER_INFLIGHT = 'PROVIDER_INFLIGHT';
export const PROVIDER_ERROR = 'PROVIDER_ERROR';
export const PROVIDER_COLLECTIONS = 'PROVIDER_COLLECTIONS';
export const PROVIDER_COLLECTIONS_INFLIGHT = 'PROVIDER_COLLECTIONS_INFLIGHT';
export const PROVIDER_COLLECTIONS_ERROR = 'PROVIDER_COLLECTIONS_ERROR';
export const NEW_PROVIDER = 'NEW_PROVIDER';
export const NEW_PROVIDER_INFLIGHT = 'NEW_PROVIDER_INFLIGHT';
export const NEW_PROVIDER_ERROR = 'NEW_PROVIDER_ERROR';
export const UPDATE_PROVIDER = 'UPDATE_PROVIDER';
export const UPDATE_PROVIDER_INFLIGHT = 'UPDATE_PROVIDER_INFLIGHT';
export const UPDATE_PROVIDER_ERROR = 'UPDATE_PROVIDER_ERROR';
export const UPDATE_PROVIDER_CLEAR = 'UPDATE_PROVIDER_CLEAR';
export const PROVIDERS = 'PROVIDERS';
export const PROVIDERS_INFLIGHT = 'PROVIDERS_INFLIGHT';
export const PROVIDERS_ERROR = 'PROVIDERS_ERROR';
export const PROVIDER_DELETE = 'PROVIDER_DELETE';
export const PROVIDER_DELETE_INFLIGHT = 'PROVIDER_DELETE_INFLIGHT';
export const PROVIDER_DELETE_ERROR = 'PROVIDER_DELETE_ERROR';
export const PROVIDER_RESTART = 'PROVIDER_RESTART';
export const PROVIDER_RESTART_INFLIGHT = 'PROVIDER_RESTART_INFLIGHT';
export const PROVIDER_RESTART_ERROR = 'PROVIDER_RESTART_ERROR';
export const CLEAR_RESTARTED_PROVIDER = 'CLEAR_RESTARTED_PROVIDER';
export const PROVIDER_STOP = 'PROVIDER_STOP';
export const PROVIDER_STOP_INFLIGHT = 'PROVIDER_STOP_INFLIGHT';
export const PROVIDER_STOP_ERROR = 'PROVIDER_STOP_ERROR';
export const CLEAR_STOPPED_PROVIDER = 'CLEAR_STOPPED_PROVIDER';
export const OPTIONS_PROVIDERNAME = 'OPTIONS_PROVIDERNAME';
export const OPTIONS_PROVIDERNAME_INFLIGHT = 'OPTIONS_PROVIDERNAME_INFLIGHT';
export const OPTIONS_PROVIDERNAME_ERROR = 'OPTIONS_PROVIDERNAME_ERROR';
export const SEARCH_PROVIDERS = 'SEARCH_PROVIDERS';
export const CLEAR_PROVIDERS_SEARCH = 'CLEAR_PROVIDERS_SEARCH';
export const FILTER_PROVIDERS = 'FILTER_PROVIDERS';
export const CLEAR_PROVIDERS_FILTER = 'CLEAR_PROVIDERS_FILTER';
// Workflows
export const WORKFLOWS = 'WORKFLOWS';
export const WORKFLOWS_INFLIGHT = 'WORKFLOWS_INFLIGHT';
export const WORKFLOWS_ERROR = 'WORKFLOWS_ERROR';
export const SEARCH_WORKFLOWS = 'SEARCH_WORKFLOWS';
export const CLEAR_WORKFLOWS_SEARCH = 'CLEAR_WORKFLOWS_SEARCH';
// Logs
export const LOGS = 'LOGS';
export const LOGS_INFLIGHT = 'LOGS_INFLIGHT';
export const LOGS_ERROR = 'LOGS_ERROR';
export const CLEAR_LOGS = 'CLEAR_LOGS';
export const SCHEMA = 'SCHEMA';
export const SCHEMA_INFLIGHT = 'SCHEMA_INFLIGHT';
export const SCHEMA_ERROR = 'SCHEMA_ERROR';
// Executions
export const EXECUTION_STATUS = 'EXECUTION_STATUS';
export const EXECUTION_STATUS_INFLIGHT = 'EXECUTION_STATUS_INFLIGHT';
export const EXECUTION_STATUS_ERROR = 'EXECUTION_STATUS_ERROR';
export const EXECUTION_LOGS = 'EXECUTION_LOGS';
export const EXECUTION_LOGS_INFLIGHT = 'EXECUTION_LOGS_INFLIGHT';
export const EXECUTION_LOGS_ERROR = 'EXECUTION_LOGS_ERROR';
export const EXECUTIONS = 'EXECUTIONS';
export const EXECUTIONS_INFLIGHT = 'EXECUTIONS_INFLIGHT';
export const EXECUTIONS_ERROR = 'EXECUTIONS_ERROR';
export const FILTER_EXECUTIONS = 'FILTER_EXECUTIONS';
export const CLEAR_EXECUTIONS_FILTER = 'CLEAR_EXECUTIONS_FILTER';
export const SEARCH_EXECUTIONS = 'SEARCH_EXECUTIONS';
export const CLEAR_EXECUTIONS_SEARCH = 'CLEAR_EXECUTIONS_SEARCH';
export const SEARCH_EXECUTION_EVENTS = 'SEARCH_EXECUTION_EVENTS';
export const CLEAR_EXECUTION_EVENTS_SEARCH = 'CLEAR_EXECUTION_EVENTS_SEARCH';
export const EXECUTIONS_LIST = 'EXECUTIONS_LIST';
export const EXECUTIONS_LIST_INFLIGHT = 'EXECUTIONS_INFLIGHT';
export const EXECUTIONS_LIST_ERROR = 'EXECUTIONS_LIST_ERROR';

// Granules executions workflows
export const WORKFLOWS_FROM_GRANULES = 'WORKFLOWS_FROM_GRANULES';
export const WORKFLOWS_FROM_GRANULES_INFLIGHT = 'WORKFLOWS_FROM_GRANULES_INFLIGHT';
export const WORKFLOWS_FROM_GRANULES_ERROR = 'WORKFLOWS_FROM_GRANULES_ERROR';
export const WORKFLOWS_FROM_GRANULES_CLEAR_ERROR = 'WORKFLOWS_FROM_GRANULES_CLEAR_ERROR';
export const CLEAR_WORKFLOWS_FROM_GRANULES = 'CLEAR_WORKFLOWS_FROM_GRANULES';

// Operations
export const OPERATIONS = 'OPERATIONS';
export const OPERATIONS_INFLIGHT = 'OPERATIONS_INFLIGHT';
export const OPERATIONS_ERROR = 'OPERATIONS_ERROR';
export const OPERATION = 'OPERATION';
export const OPERATION_INFLIGHT = 'OPERATION_INFLIGHT';
export const OPERATION_ERROR = 'OPERATION_ERROR';
export const SEARCH_OPERATIONS = 'SEARCH_OPERATIONS';
export const CLEAR_OPERATIONS_SEARCH = 'CLEAR_OPERATIONS_SEARCH';
export const FILTER_OPERATIONS = 'FILTER_OPERATIONS';
export const CLEAR_OPERATIONS_FILTER = 'CLEAR_OPERATIONS_FILTER';
// Rules
export const RULES = 'RULES';
export const RULES_INFLIGHT = 'RULES_INFLIGHT';
export const RULES_ERROR = 'RULES_ERROR';
export const RULE = 'RULE';
export const RULE_INFLIGHT = 'RULE_INFLIGHT';
export const RULE_ERROR = 'RULE_ERROR';
export const UPDATE_RULE = 'UPDATE_RULE';
export const UPDATE_RULE_INFLIGHT = 'UPDATE_RULE_INFLIGHT';
export const UPDATE_RULE_ERROR = 'UPDATE_RULE_ERROR';
export const UPDATE_RULE_CLEAR = 'UPDATE_RULE_CLEAR';
export const NEW_RULE = 'NEW_RULE';
export const NEW_RULE_INFLIGHT = 'NEW_RULE_INFLIGHT';
export const NEW_RULE_ERROR = 'NEW_RULE_ERROR';
export const RULE_DELETE = 'RULE_DELETE';
export const RULE_DELETE_INFLIGHT = 'RULE_DELETE_INFLIGHT';
export const RULE_DELETE_ERROR = 'RULE_DELETE_ERROR';
export const RULE_RERUN = 'RULE_RERUN';
export const RULE_RERUN_INFLIGHT = 'RULE_RERUN_INFLIGHT';
export const RULE_RERUN_ERROR = 'RULE_RERUN_ERROR';
export const RULE_ENABLE = 'RULE_ENABLE';
export const RULE_ENABLE_INFLIGHT = 'RULE_ENABLE_INFLIGHT';
export const RULE_ENABLE_ERROR = 'RULE_ENABLE_ERROR';
export const RULE_DISABLE = 'RULE_DISABLE';
export const RULE_DISABLE_INFLIGHT = 'RULE_DISABLE_INFLIGHT';
export const RULE_DISABLE_ERROR = 'RULE_DISABLE_ERROR';
export const SEARCH_RULES = 'SEARCH_RULES';
export const CLEAR_RULES_SEARCH = 'CLEAR_RULES_SEARCH';
export const FILTER_RULES = 'FILTER_RULES';
export const CLEAR_RULES_FILTER = 'CLEAR_RULES_FILTER';
// Reports
export const RECONCILIATION = 'RECONCILIATION';
export const RECONCILIATION_INFLIGHT = 'RECONCILIATION_INFLIGHT';
export const RECONCILIATION_ERROR = 'RECONCILIATION_ERROR';
export const RECONCILIATIONS = 'RECONCILIATIONS';
export const RECONCILIATIONS_INFLIGHT = 'RECONCILIATIONS_INFLIGHT';
export const RECONCILIATIONS_ERROR = 'RECONCILIATIONS_ERROR';
export const RECONCILIATION_DELETE = 'RECONCILIATION_DELETE';
export const RECONCILIATION_DELETE_INFLIGHT = 'RECONCILIATION_DELETE_INFLIGHT';
export const RECONCILIATION_DELETE_ERROR = 'RECONCILIATION_DELETE_ERROR';
export const SEARCH_RECONCILIATIONS = 'SEARCH_RECONCILIATIONS';
export const CLEAR_RECONCILIATIONS_SEARCH = 'CLEAR_RECONCILIATIONS_SEARCH';
export const FILTER_RECONCILIATIONS = 'FILTER_RECONCILIATIONS';
export const CLEAR_RECONCILIATIONS_FILTER = 'CLEAR_RECONCILIATIONS_FILTER';
export const NEW_RECONCILIATION = 'NEW_RECONCILIATION';
export const NEW_RECONCILIATION_INFLIGHT = 'NEW_RECONCILIATION_INFLIGHT';
export const NEW_RECONCILIATION_ERROR = 'NEW_RECONCILIATION_ERROR';
export const SEARCH_RECONCILIATION = 'SEARCH_RECONCILIATION';
export const CLEAR_RECONCILIATION_SEARCH = 'CLEAR_RECONCILIATION_SEARCH';
export const FILTER_RECONCILIATION = 'FILTER_RECONCILIATION';
export const CLEAR_RECONCILIATION_FILTER = 'CLEAR_RECONCILIATION_FILTER';
// Tokens
export const DELETE_TOKEN = 'DELETE_TOKEN';
export const REFRESH_TOKEN = 'REFRESH_TOKEN';
export const REFRESH_TOKEN_ERROR = 'REFRESH_TOKEN_ERROR';
export const REFRESH_TOKEN_INFLIGHT = 'REFRESH_TOKEN_INFLIGHT';
export const SET_TOKEN = 'SET_TOKEN';
// API
export const API_VERSION = 'API_VERSION';
export const API_VERSION_ERROR = 'API_VERSION_ERROR';
export const API_VERSION_COMPATIBLE = 'API_VERSION_COMPATIBLE';
export const API_VERSION_INCOMPATIBLE = 'API_VERSION_INCOMPATIBLE';
export const NOOP = 'NOOP';
export const CALL_API = 'CALL_API';
// CMR
export const CMR_INFO = 'CMR_INFO';
// Modals
export const ASYNC_COMMAND = 'ASYNC_COMMAND';
export const BATCH_ASYNC_COMMAND = 'BATCH_ASYNC_COMMAND';
export const COLLECTION_DELETED_CONFIRM_MODAL = 'COLLECTION_DELETED_CONFIRM_MODAL';
export const DELETE_COLLECTION_MODAL = 'DELETE_COLLECTION_MODAL';
export const GRANULES_REDIRECT_MODAL = 'GRANULES_REDIRECT_MODAL';
export const PROCESSING_MODAL = 'PROCESSING_MODAL';
// Datepicker filtering
export const DATEPICKER_DROPDOWN_FILTER = 'DATEPICKER_DROPDOWN_FILTER';
export const DATEPICKER_DATECHANGE = 'DATEPICKER_DATECHANGE';
export const DATEPICKER_HOUR_FORMAT = 'DATEPICKER_HOUR_FORMAT';
// timer state
export const TIMER_STOP = 'TIMER_STOP';
export const TIMER_START = 'TIMER_START';
export const TIMER_SET_COUNTDOWN = 'TIMER_SET_COUNTDOWN';
// Sidebar
export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR';
// Sorting Mechanism
export const SORTS = 'SORTS';
// ORCA recovery status
export const RECOVERY_GRANULE = 'RECOVERY_GRANULE';
export const RECOVERY_GRANULE_INFLIGHT = 'RECOVERY_GRANULE_INFLIGHT';
export const RECOVERY_GRANULE_ERROR = 'RECOVERY_GRANULE_ERROR';
